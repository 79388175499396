@import url('https://fonts.googleapis.com/css2?family=Bruno+Ace+SC&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap');

.container {
    width: 76rem;
    margin: 0 auto;
}

.wrapper {
    background-color: black;
    background-image: url("../../images/fullpage_background.png");
    background-size: cover;
    position: relative;
}

.custom-font {
    font-family: 'Bruno Ace SC', sans-serif;

}

.mainTitle {
    font-size: 48px;
    font-family: 'Bruno Ace SC', sans-serif;
    color: white;
    text-align: left;
    padding-top: 74px;
}

.secondaryTitle {
    width: 581px;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 20px;
    color: white;
    text-align: left;
    margin-top: 27px;
}

.borderFrameFirst {
    font-family: 'Nunito Sans', sans-serif;
    font-size: 16px;
    color: white;
    line-height: 22px;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    border: 1px solid white;
    text-align: left;
    padding: 24px 0 17px 24px;
}

.borderFrameSecond {
    line-height: 22px;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 16px;
    color: white;
    border: 1px solid white;
    text-align: left;
    border-top: none;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    padding: 24px 0 17px 24px;
    margin-bottom: 61px;
}

.buttonRegistration {
    /*box-sizing: content-box;*/
    background: linear-gradient(59.73deg, #31D054, #0F64E4);
    padding: 10px 0;
    color: white !important;
    border: unset !important;
    border-radius: 4px;
    font-family: 'Nunito Sans', sans-serif;
    width: 343px;
    line-height: 24px;
    font-weight: 600;
    height: 44px;
    cursor: pointer;
    text-decoration: none !important;
}

.buttonTelegramRegistration {
    width: 344px;
    line-height: 24px;
    font-weight: 600;
    height: 44px;
    /*box-sizing: content-box;*/
    background: transparent;
    padding: 10px 0;
    /*color: linear-gradient(to right, #31D054, #0F64E4);*/
    border: 1px solid;
    border-image: linear-gradient(59.73deg, #31D054, #0F64E4) 1;
    border-radius: 4px;

    background: linear-gradient(59.73deg, #31D054, #0F64E4), linear-gradient(0deg, #fff, #fff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Nunito Sans', sans-serif;

    cursor: pointer;
    /*position: relative;*/
    /*padding: 20px;*/
    /*font-family: 'Bruno Ace SC', sans-serif;*/
    /*color: white;*/
    /*background: black; !* Adjust the background as needed *!*/
    /*border-radius: 4px;*/
}

.whiteTitle {
    color: white;
    font-size: 32px;
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 700;
    text-align: left;
    line-height: 44px;
}

.customCard {
    width: 385px;
    display: block;
    background-color: rgba(33,33,33,0.5);
    padding: 20px 16px;
    border-radius: 12px;
}

.cardImgBox {
    text-align: left;
    margin-bottom: 16px;
}

.card_img{
    width: 80px;
}

.cardTextBox {
    text-align: left;
    font-family: 'Nunito Sans', sans-serif;
    color: white;
    font-size: 18px;
}
.speakerBoxRow {
    display: flex;
    justify-content: space-around;
}

.speakerCardImg {
    border-radius: 150px;
}

.speakerCardName {
    font-family: 'Nunito Sans', sans-serif;
    color: white;
    font-size: 28px;
    line-height: 32px;
    font-weight: 600;
    margin-top: 18px;
    margin-bottom: 14px;
}

.speakerCardOrg {
    font-family: 'Nunito Sans', sans-serif;
    color: white;
    font-size: 24px;
    line-height: 32px;
    font-weight: 400;
}

.programmPart {
    display: block;
    background-color: rgba(33,33,33,0.5);
    padding: 16px;
    border-radius: 20px;
    flex-direction: column;
    justify-content: flex-start;
}

.time {
    background: linear-gradient(59.73deg, #31D054, #0F64E4), linear-gradient(0deg, #fff, #fff);
    color: white;
    padding: 6px 12px;
    width: fit-content;
    border-radius: 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
}

.partName {
    display: block;
    margin-top: 12px;
    background-color: #2E363C99;
    padding: 8px 12px;
    border-radius: 16px;
    text-align: left;
    font-family: 'Nunito Sans', sans-serif;
    color: white;
    font-size: 20px;
    font-weight: 400;
    line-height: 27px;
}

.whoSpeach {
    border-radius: 16px;
    width: fit-content;
    background-color: #2E363C99;
    display: block;
    margin: 12px 16px 0 0;
    padding: 8px 12px;
    text-align: left;
    font-family: 'Nunito Sans', sans-serif;
    color: white;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
}
.form_wrapper{
    width: 345px;
    margin: 0 auto;
}

.form_input {
    height: 44px;
    width: 345px;
    font-size: 16px;
    background-color: transparent !important;
    border: 1px solid #707A82;
    color: #707A82;
}

.form_input:hover {
    border: 1px solid #77DFC0;
}

.form_input:focus {
    border: 1px solid #77DFC0;
}

.form_input_birthday {
    height: 44px;
    font-size: 16px; /* Ensure font size to prevent zoom on focus */
    padding: 10px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    width: 345px;
    /*max-width: 100%; !* Ensure it looks good on mobile *!*/
    box-sizing: border-box;
    text-align: left;
    display: flex;
}

.form_text{
    text-align: left;
    color: #87898F;
    font-family: MailSansRoman;
    font-weight: 300;
    margin-top: 22px;
}

.foqus_logo{
    width: 208px;
    height: 51px;
    margin-top: 53px;
}

.logo_wrapper{
    text-align: left;
}

.header_chart_wrapper{
    position: absolute;
    right: 0;
}

.header_chart{
    width: 545px;
    height: 510px;
}

.frames_wrapper{
    margin-top: 42px;
    width: 344px;
}

.promo_buttons_wrapper{
    margin-top: 64px;
    display: flex;
    align-items: center;
    column-gap: 12px;
}

.features_wrapper{
    margin-top: 217px;
}

.features_card_wrapper{
    display: flex;
    margin-top: 50px;
    justify-content: space-between;
}

.speakers_wrapper{
    margin-top: 120px;
}

.speakers_cards_wrapper{
    display: flex;
    margin-top: 50px;
    justify-content: space-between;
}

.program_wrapper{
    margin-top: 100px;
}

.program_part_wrapper{
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    margin-top: 50px;
    justify-content: space-between;
}

.organizers_wrapper{
    margin-top: 100px;
}

.organizers_items_wrapper{
    margin-top: 85px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.footer_wrapper{
    background-color: #11191F;
    margin-top: 48px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding: 46px 0;
}

.contacts_wrapper{
    margin-top: 48px;
    display: flex;
    column-gap: 48px;
}

.contacts_item{
    display: flex;
    column-gap: 10px;
}

.contacts_text{
    font-family: "Nunito Sans", sans-serif;
    color: white;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
}

/*.buttonTelegramRegistration::before {*/
/*    content: '';*/
/*    position: absolute;*/
/*    top: -2px;*/
/*    left: -2px;*/
/*    right: -2px;*/
/*    bottom: -2px;*/
/*    z-index: -1;*/
/*    background: linear-gradient(to right, #31D054, #0F64E4);*/
/*    border-radius: 4px; !* Match the border-radius of the parent element *!*/
/*}*/

.footer_title{
    font-size: 24px !important;
}

.regform_wrapper{
    margin-top: 100px;
}

.form_buttons_wrapper{
    display: flex;
    flex-direction: column;
    row-gap: 16px;
}

.form_buttons_separator{
    color: white;
    font-size: 15px;
    line-height: 20px;
    font-family: "Nunito Sans", sans-serif;
    font-weight: 400;
}

.reg_success_wrapper{
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: #21212180;
    z-index: 1;
    top: 0;
}

.success_box{
    position: absolute;
    width: 310px;
    padding: 24px 30px;
    background-color: #1D2124;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.success_close_button{
    position: absolute;
    top: 8px;
    right: 8px;
    background-color: transparent !important;
    border: unset !important;
    color: #4C4C4C !important;
}

.success_title{
    font-family: "Nunito Sans", sans-serif;
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;
    color: #FFFFFF;
    margin-top: 30px;
}

.success_subtitle{
    font-family: "Nunito Sans", sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #FFFFFF;
    margin-top: 30px;
}

.loading{
    filter: brightness(0.7);
    pointer-events: none;
}

@media (max-width: 1200px) {
    .container{
        width: calc(100% - 30px);
    }
    .secondaryTitle{
        width: 100%;
    }
    .promo_buttons_wrapper{
        flex-direction: column;
        row-gap: 12px;
    }

    .features_wrapper{
        margin-top: 94px;
    }

    .features_card_wrapper{
        flex-direction: column;
        row-gap: 12px;
    }

    .customCard{
        width: 100%;
    }

    .card_img{
        width: 60px;
    }

    .speakerCardImg{
        width: 164px;
        height: 212px;
    }

    .speakers_cards_wrapper{
        flex-wrap: wrap;
        row-gap: 62px;
    }

    .speakerCardName{
        font-size: 24px;
    }

    .speakerCardOrg{
        font-size: 18px;
    }

    .organizers_items_wrapper{
        flex-direction: column;
        row-gap: 63px;
    }

    .footer_wrapper{
        margin-top: 96px;
    }

    .whiteTitle{
        font-size: 28px;
    }

    .contacts_wrapper{
        flex-direction: column;
        row-gap: 23px;
    }

    .contacts_text{
        font-size: 15px;
        line-height: 20px;
    }
}
