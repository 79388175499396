.counter{
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    font-family: Manrope;
    color: #d9d9d9
}

.exit_button{
     position: absolute;
     left: 16px;
     top: 7px;
     border: unset !important;
     background: unset !important;
     box-shadow: unset !important;
     font-family: Manrope;
     font-weight: 600;
     font-size: 16px;
     line-height: 24px;
     color: #d9d9d9;
     padding: 0;
}

.question{
    background-color: #EFF6FF;
    padding: 14px;
    color: #005FF9;
    line-height: 24px;
    font-family: Manrope;
    font-weight: 600;
    font-size: 20px;
    border-radius: 12px;
    text-align: left;
}

.content_wrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 36px;
    justify-content: space-between;
    height: 100%;
}

.answers_wrapper{
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    margin-bottom: 94px;
}

.answers_title{
    color: #B2B2B2;
    font-family: Manrope;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: left;
}

.answer_item{
    padding: 14px 12px;
    border: 1px solid #CFCFCF;
    border-radius: 4px;
    font-family: Manrope;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
}

.answer_active{
    background: #005FF9;
    border: 1px solid #005FF9;
    color: white;
}

.buttons_wrapper{
    background: white;
    padding: 21px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 8px;
}

.button{
    font-weight: 500;
    font-family: Manrope;
    font-size: 16px;
    line-height: 20px;
    width: 50%;
    height: 44px;
    padding: 12px;
}

.back_button{
    border: 1px solid #005FF9;
    color: #005FF9;
}
