@import url('https://fonts.googleapis.com/css2?family=Golos+Text:wght@400..900&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

.landingfoqus_wrapper{
    text-align: left;
    background: #01061B;
    position: relative;
    overflow: hidden;
}

.container {
    width: 90rem;
    margin: 0 auto;
    z-index: 2;
    position: relative;
}

.header{
    background: #01061B;
    text-align: left;
    padding: 28px 0;
    position: fixed;
    width: 100%;
    z-index: 4;
}

.header_wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header_menu_wrapper{
    display: flex;
    flex-direction: row;
    color: white;
    list-style: none;
    column-gap: 5rem;
    padding: unset;
    margin: unset;
    align-items: center;
}

.header_menu_item{
    color: white !important;
    text-decoration: none !important;
    cursor: pointer;
    font-size: 24px;
    line-height: 28.8px;
    font-family: Manrope;
    font-weight: 400;
    transition: 0.3s all;
}

.header_active_item{
    color: #357AF6;
}

.header_icon{
    width: 40px;
    height: 40px;
    margin-right: 48px;
}

.header_login_wrapper{
    display: flex;
    align-items: center;
}

.login_button{
    position: relative;
    color: white !important;
    padding: 10px 20px 12px 20px;
    font-size: 24px;
    line-height: 32px;
    font-weight: 500;
    font-family: Manrope;
    cursor: pointer;
    display: flex;
    align-items: center;
    text-decoration: none !important;
}

.login_button::before{
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 50px;
    padding: 2px;
    background: linear-gradient(59.73deg, #31D054, #0F64E4);
    -webkit-mask:
            linear-gradient(#fff 0 0) content-box,
            linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
}

.header_foqus_logo{
    width: 180px;
    z-index: 1;
    position: relative;
    height: 45px;
    cursor: pointer;
}

.promo_wrapper{
    margin-top: 306px;
    position: relative;
}

.promo_title{
    display: inline-block;
    font-family: Mulish;
    font-size: 72px;
    line-height: 80px;
    font-weight: 700;
    background: linear-gradient(64.55deg, #31D054 23.05%, #0F64E4 58.11%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.promo_subtitle{
    font-family: Mulish;
    font-weight: 300;
    font-size: 72px;
    line-height: 80px;
    color: white;
}

.promo_button{
    width: 242px;
    margin-top: 130px;
    position: relative;
    color: white !important;
    padding: 10px 20px 12px 20px;
    font-size: 26px;
    line-height: 32px;
    font-weight: 500;
    font-family: Manrope;
    cursor: pointer;
    display: flex;
    align-items: center;
    text-decoration: none !important;
}

.promo_button::before{
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 50px;
    padding: 2px;
    background: linear-gradient(59.73deg, #31D054, #0F64E4);
    -webkit-mask:
            linear-gradient(#fff 0 0) content-box,
            linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
}

.promo_img{
    position: absolute;
    top: -105px;
    right: -132px;
}

.business_wrapper{
    margin-top: 392px;
}

.business_title{
    font-family: Manrope;
    font-size: 80px;
    line-height: 64px;
    color: white;
    font-weight: 400;
    text-align: center;

}

.business_hard{
    font-weight: 300;
    display: inline-block;
    position: relative;
}

.business_hard::before{
    content: '';
    height: 7px;
    width: 110%;
    background: #357AF6B2;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -5%;
}

.business_subtitle{
    font-family: Manrope;
    font-size: 36px;
    line-height: 40px;
    color: #FFFFFF99;
    text-align: center;
    margin-top: 62px;
}

.business_laptop{
    display: block;
    margin: 357px auto 0 auto;
    z-index: 2;
    position: relative;
}

.business_partners{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 59px;
    column-gap: 68px;
}

/*.aws_logo{*/
/*    display: block;*/
/*    margin: 44px auto 0 auto;*/
/*}*/

.vector_right{
    position: absolute;
    z-index: 1;
    top: -35px;
    right: 155px;
}

.vector_left{
    position: absolute;
    z-index: 1;
    top: -200px;
    left: 155px;
}

.business_info_block{
    position: absolute;
    background: linear-gradient(109.46deg, rgba(37,45,84,0.8) 1.57%, rgba(27,31,51,0.1) 100%);
    z-index: 3;
    color: white;
    font-size: 20px;
    line-height: 24px;
    font-family: Manrope;
    text-align: center;
    border-radius: 12px;
    backdrop-filter: blur(31.8px);
}

.business_search_wrapper{
    position: absolute;
    top: -244px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 4;
    border-radius: 12px;
}

.business_search_wrapper::before{
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 12px;
    padding: 2px;
    background: linear-gradient(90deg, #357FEE, rgba(49,208,84,0.38));
    -webkit-mask:
            linear-gradient(#fff 0 0) content-box,
            linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
}

.business_search_item{
    background: linear-gradient(122deg, rgba(37,45,84,0.8) 0%, rgba(27,31,51,0.1) 100%);
    padding: 9px 20px;
    color: white;
    font-size: 20px;
    display: flex;
    align-items: center;
    column-gap: 8px;
    margin-top: 1px;
    font-weight: 500;
    font-family: AvenirNextCyr;
}

.business_search_item:nth-last-child(2){
    border-radius: 0 0 12px 12px;
}

.business_search_item_main{
    font-size: 30px;
    padding: 18.5px 20px;
    position: relative;
    border-radius: 12px;
    margin-top: unset;
}

.business_search_item_main::before{
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 12px;
    padding: 4px;
    background: linear-gradient(40deg, rgba(81,192,255,1) 0%, rgba(251,62,255,1) 100%);
    -webkit-mask:
            linear-gradient(#fff 0 0) content-box,
            linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
}

.business_search_stars{
    position: absolute;
    top: -14px;
    left: -14px;
}

.business_search_cursor{
    width: 1px;
    height: 32px;
    background: #4A4A4A;
    animation: blink 1s infinite;
}

.business_search_union{
    position: absolute;
    bottom: calc(-100% + 49px);
    left: 50%;
    transform: translateX(-50%);
}

@keyframes blink {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.advantages_wrapper{
    margin-top: 200px;
    position: relative;
    z-index: 3;
}

.advantages_wrapper_stick{
    position: fixed;
    top: -79px;
    left: 50%;
    transform: translateX(-50%);
}

.advantages_wrapper_bottom{
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
}

.advantages_scroll_wrapper{
    height: 2493px;
    position: relative;
}

.advantages_title{
    font-size: 48px;
    line-height: 66px;
    font-weight: 500;
    font-family: Manrope;
    color: white;
    display: flex;
    align-items: center;
}

.advantages_cards_wrapper{
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    margin-top: 70px;
    position: relative;
}

.advantages_card{
    background: #0B1027;
    /*background: linear-gradient(109.46deg, rgba(37, 45, 84, 0.64) 1.57%, rgba(27, 31, 51, 0.08) 100%);*/
    padding: 64px 40px 48px 70px;
    border-radius: 32px;
    height: 700px;
    position: relative;
    overflow: hidden;
    z-index: 1;
}

.advantages_moving_card{
    position: absolute;
    width: 100%;
}

.advantages_card_title{
    font-size: 64px;
    line-height: 72px;
    font-weight: 500;
    font-family: Manrope;
    color: white;
    display: flex;
    align-items: center;
}

.advantages_subcards_wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: calc(100% - 144px);
    margin-top: 72px;
    column-gap: 20px;
}

.advantages_subcard{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    height: 100%;
    text-align: left;
}

.advantages_subcard_2{
    justify-content: flex-start;
    width: 362px;
}

.advantages_subcard_title{
    font-size: 36px;
    line-height: 36px;
    font-weight: 400;
    font-family: Manrope;
    color: white;
}

.advantages_subcard_title_2{
    margin-top: 36px;
    margin-bottom: 12px;
}

.advantages_subcard_desc{
    font-size: 20px;
    font-weight: 400;
    font-family: Manrope;
    color: white;
}

.advantages_subcard_dots{
    background: #00000026;
    border-radius: 11px;
    padding: 10px;
    list-style: none;
    color: white;
    margin-top: 21px;
}

.advantages_subcard_dot{
    font-size: 18px;
    font-weight: 400;
    font-family: Manrope;
    line-height: 24.59px;
    padding-left: 20px;
    position: relative;
}

.advantages_subcard_dot::before{
    content: '';
    width: 5px;
    height: 5px;
    background: white;
    position: absolute;
    border-radius: 100%;
    top: 50%;
    transform: translateY(-50%);
    left: 5px;
}

.advantages_line{
    position: absolute;
    bottom: 48px;
}

.advantages_gradient{
    background: linear-gradient(64.55deg, #31D054 23.05%, #0F64E4 58.11%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.trustedBy_wrapper{
    margin-top: 200px;
    position: relative;
    z-index: 2;
}

.trustedBy_title{
    color: #FFFFFF99;
    font-weight: 400;
    font-family: Manrope;
    font-size: 40px;
    line-height: 98.5px;
    text-align: center;
}

/*.trustedBy_items_wrapper{*/
/*    display: flex;*/
/*    justify-content: space-between;*/
/*    align-items: center;*/
/*}*/

.trustedBy_item{
    /*margin: 0 50px;*/
    position: relative;
    height: 247px;
    width: 247px;
    display: flex !important;
    align-items: center;
    justify-content: center;
}

/*.trustedBy_item::before{*/
/*    content: '';*/
/*    position: absolute;*/
/*    top: 50%;*/
/*    transform: translateY(-50%);*/
/*    right: 0;*/
/*    width: 1px;*/
/*    height: 60px;*/
/*    background: #FFFFFF80;*/
/*}*/

.trustedBy_img{
    margin: 0 68px;
}

.trustedBy_separator{
    height: 60px;
    width: 1px !important;
    /*margin: 100px auto 0 auto;*/
    background: #FFFFFF80;
    display: block !important;
}

.caseStudy_wrapper{
    margin-top: 280px;
}

.caseStudy_title{
    color: white;
    font-weight: 500;
    font-family: Manrope;
    font-size: 48px;
    line-height: 66px;
    display: flex;
    align-items: center;
}

.caseStudy_card{
    transition: 0.5s all;
    /*padding: 42px;*/
    position: relative;
    margin: 0 20px;
    overflow: hidden;
}

.caseStudy_card:hover{
    .caseStudy_text_block{
        opacity: 1;
    }
    .caseStudy_card_overlay{
        opacity: 0.5;
    }
    .caseStudy_img{
        /*opacity: 0;*/
    }
    .caseStudy_img_hover{
        opacity: 1;
    }
}

.caseStudy_card_overlay{
    cursor: pointer;
    transition: 0.5s all;
    position: absolute;
    width: 100%;
    height: 100%;
    background: black;
    opacity: 0;
    top: 0;
    left: 0;
    border-radius: 16px;
    z-index: 1;
}

.caseStudy_text_block{
    position: absolute;
    opacity: 0;
    transition: 0.5s all;
    z-index: 2;
    bottom: 42px;
    left: 42px;
}

.caseStudy_card_title{
    color: white;
    font-size: 32px;
    font-weight: 600;
    font-family: Manrope;
    line-height: 44px;
}

.caseStudy_card_desc{
    color: white;
    font-family: Manrope;
    font-weight: 500;
    font-size: 24px;
    line-height: 33px;
}

.caseStudy_img{
    /*width: 52rem;*/
    transition: 0.5s all;
}

.caseStudy_img_hover{
    transition: 0.5s all;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
}

.caseStudy_card_text{
    position: absolute;
    bottom: 0;
    background: rgba(1, 6, 27, 0.81);
    width: 100%;
    padding: 18px 12px 18px 23px;
    font-size: 20px;
    color: white;
    font-family: Manrope;
}


.howItWorks_wrapper{
    margin-top: 200px;
    /*margin-top: 2484px;*/
    position: relative;
    z-index: 2;
}

.howItWorks_title{
    color: white;
    font-family: Manrope;
    font-weight: 700;
    font-size: 80px;
    line-height: 80px;
    text-align: center;
}

.howItWorks_subtitle{
    font-family: Manrope;
    font-weight: 300;
    font-size: 40px;
    line-height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    column-gap: 22px;
    margin-top: 77px;
    position: relative;
}

.howItWorks_subsubtitle{
    font-family: Manrope;
    font-weight: 300;
    font-size: 30px;
    line-height: 48px;
    text-align: center;
    color: #FFFFFF80;
    margin-top: 14px;
}

.howItWorks_cards_wrapper{
    justify-content: center;
    display: flex;
    align-items: center;
    column-gap: 25px;
    margin-top: 220px;
    position: relative;
}

.howItWorks_card{
    position: relative;
    padding: 38px 40px;
    height: 540px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.howItWorks_card::before{
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 0px 22px 22px 22px;
    padding: 2px;
    background: linear-gradient(59.73deg, #31D054, #0F64E4);
    -webkit-mask:
            linear-gradient(#fff 0 0) content-box,
            linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
}

.howItWorks_card_step{
    position: absolute;
    font-size: 32px;
    line-height: 36px;
    font-weight: 400;
    font-family: Manrope;
    padding: 6px 17px;
    top: -24px;
    left: 0;
    background: #01061B;
    border-radius: 13px;
}

.gradient_text{
    background: linear-gradient(64.55deg, #31D054 23.05%, #0F64E4 58.11%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.howItWorks_card_step::before{
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 13px;
    padding: 2px;
    background: linear-gradient(59.73deg, #31D054, #0F64E4);
    -webkit-mask:
            linear-gradient(#fff 0 0) content-box,
            linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
}

.howItWorks_card_title{
    font-family: Manrope;
    font-weight: 400;
    font-size: 32px;
}

.howItWorks_card_desc{
    color: white;
    padding-left: 20px;
    font-size: 18px;
    line-height: 21.6px;
    font-family: Manrope;
    font-weight: 400;
    margin-top: 26px;
    margin-bottom: 0px;
}

.howItWorks_card_3{
    display: flex;
    column-gap: 16px;
}

.howItWorks_arrow_bottom{
    position: absolute;
    left: 50%;
    bottom: -85px;
}

.howItWorks_arrow_top{
    position: absolute;
    top: -85px;
    left: 350px;
}

.howItWorks_splat{
    position: absolute;
    top: -130px;
}

.pointer_top{
    position: absolute;
    top: -14px;
    right: 539px;
}

.pointer_bottom{
    position: absolute;
    transform: rotate(-180deg);
    bottom: -14px;
    right: 185px;
}

.requestDemo_wrapper{
    margin-top: 200px;
    z-index: 2;
    position: relative;
}

.requestDemo_card{
    padding: 84px 98px;
    background: linear-gradient(140deg, rgba(37,45,84,0.4) 0%, rgba(27,31,51,0.1) 100%);
    border-radius: 34.39px;
    display: flex;
    justify-content: space-between;
}

.requestDemo_card::before{
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 34.39px;
    padding: 2px;
    background: linear-gradient(140deg, rgba(255,255,255,0.4) 0%, rgba(211,211,211,0.1) 100%);
    -webkit-mask:
            linear-gradient(#fff 0 0) content-box,
            linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
}

.requestDemo_info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.requestDemo_title{
    font-size: 60px;
    line-height: 100%;
    font-weight: 700;
    font-family: Manrope;
    color: white;
}

.requestDemo_subtitle{
    font-size: 24px;
    line-height: 32px;
    font-family: Manrope;
    font-weight: 400;
    color: white;
    margin-top: 37px;
}

.requestDemo_links_wrapper{
    display: flex;
    column-gap: 46px;
    margin-top: 35px;
    position: relative;
    z-index: 2;
}

.requestDemo_subdesc{
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    font-family: Manrope;
    color: #FFFFFF80;
}

.requestDemo_form_label{
    font-size: 15px;
    font-weight: 400;
    line-height: 20.49px;
    color: white;
    font-family: Manrope;
}

.requestDemo_form_input{
    width: 726px;
    border-radius: 9px;
    padding: 20px 26px 17px 26px;
    color: white;
    font-size: 19px;
    font-weight: 300;
    font-family: Manrope;
    line-height: 26.1px;
    border: unset;
    background: linear-gradient(109.46deg, rgba(201, 201, 201, 0.46) 1.57%, rgba(196, 196, 196, 0.1) 100%) !important;
    position: relative;
}

.requestDemo_form_input:focus::placeholder {
    color: transparent;
}

.requestDemo_form_input_wrapper{
    transition: 5s all;
}

.requestDemo_form_input_wrapper:focus-within::before{
    background: linear-gradient(140deg, #31D054 4%, #0F64E4 100%);
}

.requestDemo_form_input_wrapper::before{
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 9px;
    padding: 1px;
    background: linear-gradient(140deg, rgba(255,255,255,0.4) 0%, rgba(211,211,211,0.1) 100%);
    -webkit-mask:
            linear-gradient(#fff 0 0) content-box,
            linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    transition: 0.5s all;
}

.requestDemo_form_button{
    margin-top: 32px;
    background: linear-gradient(4deg, #31D054 -17.7%, #0F64E4 93.02%) !important;
    border: unset !important;
    width: 100%;
    font-size: 24px;
    font-weight: 600;
    font-family: Manrope;
    line-height: 33px;
    height: 64px;
}

.footer_title{
    font-size: 32px;
    color: white;
    line-height: 38.4px;
    font-family: Manrope;
    font-weight: 500;
}

.footer_text{
    font-family: Manrope;
    color: white;
    font-size: 24px;
    line-height: 40.8px;
    font-weight: 400;
    margin-top: 23px;
}

.footer_subtext{
    font-family: Mulish;
    font-size: 20px;
    line-height: 32px;
    font-weight: 400;
    color: white;
    margin-top: 24px;
    text-align: center;
}

.footer_wrapper{
    padding-top: 109px;
    padding-bottom: 65px;
    position: relative;
    z-index: 2;
}

.footer_items_warpper{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.footer_logo{
    /*width: 260px;*/
    z-index: 2;
    position: relative;
}

.footer_logo_splat{
    position: absolute;
    top: -80px;
    right: 50px;
    z-index: 1;
}

.footer_menu{
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

.footer_menu_item{
    font-size: 24px;
    line-height: 170%;
    color: white !important;
    font-family: Manrope;
    font-weight: 600;
    text-decoration: none !important;
    display: block;
    cursor: pointer;
}

.footer_info{
    display: flex;
    flex-direction: column;
    row-gap: 18px;
}

.footer_info_text{
    color: white;
    font-size: 24px;
    line-height: 170%;
    font-family: Manrope;
    font-weight: 400;
}

.footer_socials{
    display: flex;
    column-gap: 24px;
}

.footer_socials_part{
    display: flex;
    column-gap: 48px;
    align-items: center;
}

.footer_splat{
    position: absolute;
    bottom: 0;
    left: 0;
}

.dots_bg{
    position: absolute;
    z-index: 1;
    width: 100%;
    top: -442px;
}

.response_popup{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
}

.response_popup_overlay{
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.response_popup_card{
    padding: 70px 181px;
    background: linear-gradient(109.46deg, rgba(37, 45, 84, 1) 1.57%, rgba(27, 31, 51, 1) 100%);
    border-radius: 34.39px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.response_popup_card::before{
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 34.39px;
    padding: 2px;
    background: linear-gradient(140deg, rgba(255,255,255,0.4) 0%, rgba(211,211,211,0.1) 100%);
    -webkit-mask:
            linear-gradient(#fff 0 0) content-box,
            linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
}

.response_popup_title{
    color: white;
    font-size: 60px;
    font-weight: 600;
    line-height: 60px;
    font-family: Manrope;
}

.response_popup_subtitle{
    color: white;
    margin-top: 61px;
    font-size: 24px;
    line-height: 32px;
    font-family: Manrope;
}

.response_popup_text{
    color: white;
}

.response_popup_button{
    background: linear-gradient(4deg, #31D054 -17.7%, #0F64E4 93.02%) !important;
    border: unset !important;
    width: 100%;
    font-size: 24px;
    font-weight: 600;
    font-family: Manrope;
    line-height: 33px;
    height: 64px;
    color: white !important;
}

.header_outclick_overlay{
    display: none;
}

.promo_header_wrapper{
    display: none;
}

.footer_logo_wrapper{
    position: relative;
}

.footer_mobile_logo{
    display: none;
    width: 173px;
}

.footer_linkedin{
    margin-top: 23px;
}

.short_line{
    display: none;
}

.short_pointer_bottom{
    display: none;
}

.blog_promo_wrapper{
    margin-top: 252px;
    position: relative;
}

.blog_promo_title{
    font-size: 80px;
    line-height: 84px;
    font-weight: 700;
    color: white;
    font-family: Manrope;
}

.blog_promo_post{
    margin-top: 93px;
    display: flex;
    justify-content: center;
    column-gap: 96px;
}

.blog_promo_post_info{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.blog_promo_post_title{
    color: white;
    font-size: 56px;
    line-height: 76.5px;
    font-family: Manrope;
}

.blog_promo_post_date{
    font-size: 20px;
    line-height: 27.35px;
    font-family: Manrope;
    font-weight: 500;
    color: white;
    margin-top: 24px;
}

.blog_post_button{
    font-size: 16px;
    line-height: 21.88px;
    color: #358346;
    font-family: Manrope;
    font-weight: 700;
    cursor: pointer;
    display: inline-block;
}

.blog_posts{
    margin-top: 256px;
    margin-bottom: 193px;
    position: relative;
}

.blog_posts_wrapper{
    display: flex;
    flex-wrap: wrap;
    column-gap: 24px;
    row-gap: 186px;
}

.blog_post{
    width: 464px;
}

.blog_post_img_wrapper{
    position: relative;
    width: 100%;
    /*height: 250px;*/
}

.blog_post_img{
    width: 100%;
    cursor: pointer;
    /*height: 100%;*/
}

.blog_post_date{
    position: absolute;
    left: 14px;
    bottom: 17px;
    background: #FFFFFF80;
    padding: 4px 8px;
    border-radius: 8px;
    font-size: 16px;
    line-height: 21.88px;
    font-weight: 500;
    color: #02071C;
    font-family: Manrope;
    backdrop-filter: blur(31.8px);
    cursor: pointer;
}

.blog_post_title{
    margin-top: 38px;
    font-size: 40px;
    line-height: 54.64px;
    font-family: Manrope;
    color: white;
    cursor: pointer;
}

.post_page_wrapper{
    margin-top: 231px;
    margin-bottom: 220px;
    position: relative;
}

.post_title{
    font-size: 56px;
    line-height: 76.5px;
    font-weight: 400;
    font-family: Manrope;
    color: white;
}

.post_time{
    font-size: 20px;
    line-height: 27.35px;
    color: white;
    font-weight: 700;
    font-family: Manrope;
    display: flex;
    align-items: center;
    column-gap: 9px;
}

.post_date{
    font-size: 20px;
    line-height: 27.35px;
    font-family: Manrope;
    font-weight: 400;
    color: white;
}

.post_subtitle{
    display: flex;
    column-gap: 40px;
    margin-top: 48px;
}

.post_promo{
    display: flex;
    margin-bottom: 114px;
}

.post_text{
    font-size: 28px;
    line-height: 39.2px;
    font-weight: 300;
    font-family: Manrope;
    color: white;
}

.post_content_img{
    margin: 64px 0;
    width: 100%;
}

.post_footer{
    margin-top: 64px;
    display: flex;
    align-items: flex-start;
}

.post_author{
    display: flex;
    column-gap: 22px;
}

.post_author_name{
    font-size: 20px;
    line-height: 27.35px;
    color: white !important;
    text-decoration: none;
    font-family: Manrope;
    font-weight: 500;
}

.name_link{
    color: white !important;
    text-decoration: none !important;
    position: relative;
}

.name_link::before{
    content: '';
    width: 100%;
    height: 1px;
    background: white;
    position: absolute;
    bottom: 3px;
}

.post_author_position{
    font-size: 16px;
    line-height: 21.88px;
    font-family: Manrope;
    font-weight: 400;
    color: white;
    margin-top: 2px;
}

.post_footer_divider{
    width: 1px;
    background: #FFFFFF26;
    margin: 0 55px;
    height: 55px;
}

.post_quote{
    font-size: 20px;
    line-height: 27.35px;
    color: white;
    font-family: Manrope;
    font-weight: 500;
    margin-left: 17px;
}

.post_button{
    margin-top: 100px;
    background: linear-gradient(89.63deg, #31D054 -0.14%, #003179 94.17%);
    padding: 33px;
    text-align: center;
    font-size: 28px;
    line-height: 39.2px;
    color: white;
    font-family: Manrope;
    font-weight: 400;
    border-radius: 12px;
    cursor: pointer;
}

.related_post{
    margin-top: 100px;
}

.related_post_title{
    font-size: 40px;
    line-height: 54.64px;
    font-weight: 600;
    font-family: Manrope;
    color: white;
}

.related_post_posts{
    display: flex;
    flex-wrap: wrap;
    column-gap: 24px;
    row-gap: 24px;
    margin-top: 80px;
}

.faq_wrapper{
    margin-top: 200px;
    position: relative;
    z-index: 3;
}

.faq_content{
    display: flex;
    justify-content: space-between;
}

.faq_title{
    font-size: 56px;
    line-height: 64px;
    font-family: Manrope;
    font-weight: 500;
    color: white;
}

.faq_collapse{
    border-radius: 12px;
    padding: 20px;
    cursor: pointer;
    width: 51rem;
    transition: 0.3s all;
    overflow: hidden;
}

.faq_collapse:hover{
    color: #1780BC;
}

.faq_collapse_title_wrapper{
    display: flex;
    color: white;
    align-items: flex-start;
    column-gap: 24px;
}

.faq_collapse_title{
    font-size: 28px;
    line-height: 32px;
    font-weight: 500;
    font-family: Manrope;
}

.faq_collapse_icon{
    font-size: 28px;
}

.faq_collapse_item_wrapper{
    color: #333333 !important;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: unset;
    overflow: hidden;
    max-height: 0;
    transition: 0.5s all;
    margin-top: 0;
    opacity: 0;
    list-style: none;
    padding: 0 0 0 56px;
}

.faq_collapse_open{
    max-height: 1000px;
    transition: 0.5s all;
    margin-top: 21px;
    opacity: 1;
}

.faq_collapse_item{
    font-size: 20px;
    color: white;
    line-height: 32px;
    font-family: Manrope;
}

.mainPosts_wrapper{
    margin-top: 200px;
    margin-bottom: 252px;
}

.mainPosts_title{
    font-size: 56px;
    font-family: Manrope;
    line-height: 64px;
    color: white;
    font-weight: 600;
}

.mainPosts_posts_wrapper{
    margin-top: 70px;
    display: flex;
    justify-content: space-between;
}

.promo_mobile_title{
    display: none;
}

.post_popup_wrapper{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 4;
}

.post_popup{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: linear-gradient(140deg, #252D54 0%, #1B1F33 100%);
    width: 90rem;
    padding: 70px;
    border-radius: 32px;
    display: flex;
    justify-content: space-between;
}

.post_popup::before{
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 34.39px;
    padding: 2px;
    background: linear-gradient(140deg, rgba(255,255,255,0.4) 0%, rgba(211,211,211,0.1) 100%);
    -webkit-mask:
            linear-gradient(#fff 0 0) content-box,
            linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
}

.post_popup_overlay{
    background: rgba(0,0,0,0.8);
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.post_popup_close{
    position: absolute;
    color: #474954;
    font-size: 28px;
    top: 28px;
    right: 51px;
}

.about_us_promo_wrapper{
    margin-top: 110px;
    padding-top: 195px;
    position: relative;
}

.about_us_promo_info{
    display: flex;
    justify-content: space-between;
}

.about_us_promo_title{
    font-size: 48px;
    line-height: 140%;
    font-weight: bold;
    font-family: Manrope;
    color: white;
    width: 341px;
}

.about_us_promo_desc{
    font-size: 24px;
    line-height: 140%;
    color: white;
    font-family: Manrope;
    font-weight: 400;
    width: 644px;
}

.about_us_promo_bg{
    position: absolute;
    top: -28px;
    width: 100%;
}

.values_wrapper{
    margin-top: 644px;
    position: relative;
}

.values_title{
    font-size: 32px;
    line-height: 120%;
    color: white;
    font-family: Manrope;
    font-weight: bold;
    text-align: center;
}

.values_top_wrapper{
    margin-top: 101px;
}

.values_bottom_wrapper{
    margin-top: 36px;
}

.values_top_wrapper, .values_bottom_wrapper{
    display: flex;
    column-gap: 24px;
    align-items: center;
    justify-content: center;
}

.value_card{
    padding: 30px 40px;
    border: 1px solid #A8A8A8;
    border-radius: 32px;
}

.value_card_title{
    display: flex;
    align-items: center;
    column-gap: 13px;
    font-size: 32px;
    line-height: 120%;
    color: white;
    font-family: Manrope;
    font-weight: bold;
}

.value_card_desc{
    font-size: 22px;
    line-height: 140%;
    color: white;
    font-family: Manrope;
    margin-top: 33px;
}

.beliefs_wrapper{
    margin-top: 225px;
}

.beliefs_title{
    color: #31D054;
    font-size: 32px;
    line-height: 120%;
    font-weight: bold;
    font-family: Manrope;
}

.beliefs_desc{
    margin-top: 15px;
    font-size: 48px;
    line-height: 140%;
    font-family: Manrope;
    font-weight: bold;
    color: white;
    max-width: 831px;
}

.beliefs_info{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.beliefs_stars{
    margin-right: 100px;
    margin-top: 20px;
}

.mission_wrapper{
    margin-top: 244px;
    margin-bottom: 225px;
    position: relative;
}

.mission_title{
    color: #31D054;
    font-size: 32px;
    line-height: 120%;
    font-weight: bold;
    font-family: Manrope;
}

.mission_desc{
    margin-top: 15px;
    font-size: 48px;
    line-height: 140%;
    font-family: Manrope;
    font-weight: bold;
    color: white;
}

.team_wrapper{
    margin-top: 225px;
    position: relative;
}

.team_title{
    color: #31D054;
    font-size: 32px;
    line-height: 120%;
    font-weight: bold;
    font-family: Manrope;
}

.team_cards_wrapper{
    display: flex;
    column-gap: 22.6px;
    row-gap: 88px;
    flex-wrap: wrap;
    margin-top: 74px;
    justify-content: center;
}

.team_card{
    max-width: 343px;
}

.team_card_name{
    color: white;
    font-size: 28.9px;
    line-height: 130%;
    font-weight: bold;
    font-family: "Open Sans";
    margin-top: 21.67px;
}

.team_card_position{
    color: white;
    font-size: 24.08px;
    line-height: 130%;
    font-weight: 400;
    font-family: "Open Sans";
    margin-top: 12px;
}

@media (max-width: 1200px) {
    .container, .post_popup {
        width: calc(100% - 32px);
    }

    .showMenu{
        right: 0;
    }

    .hideMenu{
        right: -100%;
    }

    .showOverlay{
        left: 0;
    }

    .hideOverlay{
        left: -100%;
    }
    .header{
        transition: 0.5s all;
        background: rgba(1, 6, 27, 0.6);
        backdrop-filter: blur(31.8px);
        position: fixed;
        top: 0;
        height: 100vh;
        width: 67%;
        padding: 81px 38px 109px 38px;
    }
    .header_wrapper{
        flex-direction: column;
        height: 100%;
    }
    .header_menu_wrapper{
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        align-items: flex-start;
        margin-bottom: 121px;
    }
    /*.header_foqus_logo{*/
    /*    display: none;*/
    /*}*/
    .header_menu_item{
        margin-bottom: 32px;
        font-size: 20px;
        line-height: 24px;
    }
    .header_menu_item:last-child{
        margin-bottom: 0;
    }
    .header_icon{
        margin-right: 22px;
        width: 24px;
    }
    .login_button{
        font-size: 20px;
        line-height: 32px;
        padding: 7px 16px;
    }
    .login_arrow{
        width: 16px;
    }
    .header_outclick_overlay{
        display: block;
        position: fixed;
        width: 33%;
        background: transparent;
        height: 100vh;
        z-index: 3;
    }

    .promo_wrapper{
        margin-top: 52px;
    }
    .promo_img{
        display: none;
    }
    .promo_header_wrapper{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .promo_logo{
        width: 180px;
        height: 45px;
    }
    .promo_hamburger{
        font-size: 24px;
        color: white;
    }
    .promo_title{
        margin-top: 52px;
        font-size: 56px;
        line-height: 64px;
        display: none;
    }
    .promo_subtitle{
        font-size: 56px;
        line-height: 64px;
        display: none;
    }
    .promo_mobile_title{
        display: block;
        font-size: 56px;
        color: white;
        line-height: 64px;
        margin-top: 60px;
    }
    .promo_button{
        margin-top: 87px;
    }

    .business_wrapper{
        margin-top: 145px;
    }
    .business_title{
        font-size: 48px;
        line-height: 54px;
        margin: 0px 3px;
    }
    .business_subtitle{
        font-size: 28px;
        line-height: 32px;
        margin: 25px 10px 0 10px;
    }
    .business_laptop{
        width: 100%;
        margin-top: 190px;
    }
    .business_partners{
        display: none;
    }
    .business_search_wrapper{
        width: 100%;
        top: unset;
        bottom: 238px;
    }
    .business_search_item_main{
        font-size: 15px !important;
        padding: 10px 17px !important;
    }
    .business_search_item_main::before{
        padding: 2.25px;
    }
    .business_search_item{
        font-size: 11.27px;
        padding: 5px 11px;
    }
    .business_search_cursor{
        height: 18px;
    }
    .business_search_icon{
        width: 9px;
        height: 9px;
    }
    .business_search_icon_main{
        width: 13px;
        height: 13px;
    }
    .business_search_stars{
        width: 14px;
        height: 13px;
        top: -7px;
        left: -8px;
    }
    .business_search_union{
        bottom: calc(-100% + 26px);
    }
    .vector_right{
        top: -69%;
        right: -60%;
        transform: scale(0.5);
    }

    .vector_left{
        top: -87%;
        left: -57%;
        transform: scale(0.5);
    }
    .aws_logo{
        width: 63%;
    }

    .advantages_wrapper{
        margin-top: 120px;
        width: 100%;
    }
    .advantages_scroll_wrapper{
        height: auto;
    }
    .advantages_title{
        font-size: 32px;
        line-height: 43.71px;
    }
    .advantages_arrow{
        width: 40px;
    }
    .advantages_card_title{
        font-size: 32px;
        line-height: 36px;
        display: unset;
    }
    .advantages_subcard_title{
        font-size: 28px;
        line-height: 28px;
    }
    .advantages_subcard_desc{
        font-size: 18px;
        line-height: 24.59px;
    }
    .advantages_subcards_wrapper{
        flex-direction: column;
        margin-top: 69px !important;
        row-gap: 72px;
        z-index: 2;
        position: relative;
    }
    .advantages_card{
        padding: 20px 20px 55px 20px !important;
        height: unset;
    }
    .advantages_img{
        width: 100%;
    }
    .advantages_line{
        display: none;
    }
    .advantages_gradient{
        display: inline;
    }
    .advantages_subcard_2{
        width: 100%;
    }


    .trustedBy_wrapper{
        margin-top: 216px;
    }
    .trustedBy_title{
        font-size: 24px;
        line-height: 32px;
    }
    .trustedBy_separator{
        height: 25px;
        /*margin-top: 110px;*/
    }
    .trustedBy_img{
        width: 104px;
    }

    .caseStudy_wrapper{
        margin-top: 152px;
    }
    .caseStudy_title{
        font-size: 32px;
        line-height: 43.71px;
    }
    .caseStudy_card{
        pointer-events: none;
    }
    .caseStudy_card_text{
        font-size: 18px;
    }

    .howItWorks_wrapper{
        margin-top: 120px;
    }
    .howItWorks_title{
        font-size: 44px;
        line-height: 45.15px;
    }
    .howItWorks_subtitle{
        margin-top: 46px;
        font-size: 22px;
        line-height: 27.09px;
    }
    .howItWorks_arrow{
        width: 75px;
    }
    .howItWorks_subsubtitle{
        font-size: 16px;
        line-height: 27.09px;
        margin-top: 7.9px;
    }
    .howItWorks_cards_wrapper{
        flex-direction: column;
        margin-top: 67px;
        row-gap: 49px;
    }
    .howItWorks_card{
        width: 74vw;
        padding: 24px;
        height: unset;
    }
    .howItWorks_card_title{
        font-size: 20px;
    }
    .howItWorks_card_desc{
        margin-top: 15px;
        font-size: 12px;
        line-height: 14.4px;
    }
    .howItWorks_splat{
        display: none;
    }
    .short_line{
        display: block;
        position: absolute;
        bottom: -49px;
        right: 43px;
    }
    .short_pointer_bottom{
        display: block;
        position: absolute;
        bottom: -60px;
        width: 21px;
        right: 35px;
        z-index: 1;
    }
    .howItWorks_arrow_bottom, .howItWorks_arrow_top, .pointer_top, .pointer_bottom{
        display: none;
    }
    .howItWorks_card_step{
        font-size: 20px;
        line-height: 21.6px;
        padding: 3.6px 10.2px;
        top: -14px;
    }

    .requestDemo_wrapper{
        margin-top: 98px;
    }
    .requestDemo_title{
        font-size: 48px;
        line-height: 48px;
    }
    .requestDemo_card, .post_popup{
        padding: 27px 33px 42px 36px;
        flex-direction: column;
    }
    .requestDemo_subtitle{
        font-size: 18px;
        line-height: 25px;
    }
    .requestDemo_subdesc{
        margin-top: 44px;
    }
    .requestDemo_form{
        margin-top: 68px;
    }
    .requestDemo_form_input{
        width: 100%;
        font-size: 18px;
        line-height: 24.59px;
    }
    .requestDemo_form_button{
        font-size: 18px;
        line-height: 24.59px;
    }

    .footer_wrapper{
        padding-top: 65px;
        padding-bottom: 70px;
    }
    .footer_items_warpper{
        flex-direction: column;
    }
    .footer_logo_wrapper{
        display: none;
    }
    .footer_mobile_logo{
        display: block;
    }
    .footer_title{
        font-size: 24px;
        line-height: 28.8px;
        margin-top: 65px;
    }
    .footer_text{
        font-size: 18px;
        line-height: 30.6px;
    }
    .footer_subtext{
        margin-top: 168px;
        font-size: 16px;
        line-height: 24px;
        text-align: left;
    }
    .footer_items_warpper{
        flex-direction: column;
    }
    .footer_info{
        margin-top: 64px;
        margin-bottom: 32px;
    }
    .footer_socials_part{
        flex-direction: column;
        align-items: flex-start;
        row-gap: 32px;
    }
    .footer_info_text{
        font-size: 20px;
        font-weight: 400;
    }
    .footer_splat{
        width: 100%;
    }
    .blog_promo_wrapper{
        margin-top: 52px;
    }
    .blog_promo_title{
        margin-top: 95px;
        font-size: 40px;
        line-height: 48px;
    }
    .blog_promo_post{
        margin-top: 58px;
        flex-direction: column;
    }
    .blog_promo_post_title{
        font-size: 40px;
        line-height: 48px;
        margin-top: 32px;
    }
    .blog_promo_post_date{
        font-size: 18px;
        line-height: 24.62px;
        margin-top: 16px;
    }
    .blog_post_button{
        margin-top: 40px;
    }
    .blog_posts{
        margin-top: 54px;
    }
    .blog_post_title{
        font-size: 32px;
        margin-top: 24px;
        line-height: unset;
    }
    .blog_posts_wrapper{
        row-gap: 54px;
    }
    .post_page_wrapper{
        margin-top: 52px;
        margin-bottom: 156px;
    }
    .post_promo{
        margin-top: 84px;
        flex-direction: column;
        row-gap: 37px;
        margin-bottom: 61px;
    }
    .post_title{
        font-size: 40px;
        line-height: 54.64px;
    }
    .post_time{
        font-size: 18px;
        line-height: 24.62px;
    }
    .post_date{
        font-size: 18px;
        line-height: 24.62px;
    }
    .post_subtitle{
        margin-top: 32px;
    }
    .post_text{
        font-size: 20px;
        line-height: 28px;
    }
    .post_button{
        font-size: 20px;
        line-height: 28px;
    }
    .faq_title{
        font-size: 32px;
        line-height: 40px;
    }
    .faq_collapse_title{
        font-size: 20px;
        line-height: 32px;
    }
    .faq_collapse_item{
        font-size: 16px;
        line-height: 20px;
    }
    .faq_content{
        flex-direction: column;
    }
    .faq_collapse{
        width: 100%;
    }
    .faq_wrapper{
        margin-top: 120px;
    }
    .mainPosts_wrapper{
        margin-top: 120px;
        margin-bottom: 106px;
    }
    .mainPosts_posts_wrapper{
        flex-direction: column;
        margin-top: 40px;
        row-gap: 54px;
    }
    .mainPosts_title{
        font-size: 32px;
    }
    .blog_post{
        width: 100%;
    }
    .dots_bg{
        display: none;
    }
    .about_us_promo_wrapper{
        margin-top: 52px;
        padding-top: unset;
    }
    .about_us_promo_info{
        margin-top: 105px;
        flex-direction: column;
    }
    .about_us_promo_desc{
        margin-top: 46px;
        width: unset;
        font-size: 20px;
    }
    .about_us_promo_bg{
        top: unset;
        bottom: -60%;
    }
    .about_us_promo_title{
        font-size: 40px;
    }
    .values_wrapper{
        margin-top: 343px;
    }
    .values_title{
        font-size: 24px;
    }
    .values_top_wrapper, .values_bottom_wrapper{
        flex-direction: column;
        row-gap: 13px;
    }
    .values_bottom_wrapper{
        margin-top: 13px;
    }
    .values_top_wrapper{
        margin-top: 76px;
    }
    .value_card{
        width: 100%;
        padding: 32px 24px;
    }
    .value_card_img{
        width: 63px;
        height: 63px;
    }
    .value_card_title{
        font-size: 24px;
    }
    .value_card_desc{
        font-size: 20px;
        margin-top: 20px;
    }
    .beliefs_wrapper{
        margin-top: 167px;
    }
    .beliefs_stars{
        display: none;
    }
    .beliefs_title{
        font-size: 24px;
    }
    .beliefs_desc{
        font-size: 32px;
    }
    .team_wrapper{
        margin-top: 167px;
    }
    .team_title{
        font-size: 24px;
    }
    .team_cards_wrapper{
        margin-top: 74px;
    }
    .mission_wrapper{
        margin-top: 167px;
        margin-bottom: 133px;
    }
    .mission_title{
        font-size: 24px;
    }
    .mission_desc{
        font-size: 32px;
    }
}
