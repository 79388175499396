body {
    background-color: #ffffff;
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
}

header {
    background-color: #f1f1f1;
    padding: 20px;
    text-align: center;
}

h1 {
    margin: 0;
}

main {
    margin: 20px;
    text-align: center;
}

p {
    margin-bottom: 20px;
}

a {
    color: #000000;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

i {
    color: orange;
    font-style: normal;
}

.quicksight-embedding-iframe {
    width: 100%;
    height: 100vh;
    display: none;
}
.ant-layout-content div .quicksight-embedding-iframe {
    display: block;
}

.player-wrapper {
    width: 30%;
    height: auto;
}

.ant-input::placeholder {
    color: #707A82;
    opacity: 1; /* Firefox */
}

.ant-input::-ms-input-placeholder { /* Edge 12-18 */
    color: #707A82;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}
