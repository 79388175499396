html{
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-spin{
  max-height: unset !important;
}

@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sofia+Sans:ital,wght@0,1..1000;1,1..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Abel&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Abel&family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap');

@font-face {
  font-family: MailSansRoman;
  src:url('./fonts/MailSansRoman-Light.ttf');
  font-weight: 300;
}
@font-face {
  font-family: MailSansRoman;
  src:url('./fonts/MailSansRoman-Regular.ttf');
  font-weight: 400;
}
@font-face {
  font-family: MailSansRoman;
  src:url('./fonts/MailSansRoman-Medium.ttf');
  font-weight: 500;
}
@font-face {
  font-family: MailSansRoman;
  src:url('./fonts/MailSansRoman-DemiBold.ttf');
  font-weight: 600;
}
@font-face {
  font-family: Avenir;
  src:url('./fonts/Avenir-Book.otf');
  font-weight: 300;
}
@font-face {
  font-family: Avenir;
  src:url('./fonts/Avenir-Heavy.otf');
  font-weight: 900;
}

@font-face{
  font-family: AvenirNextCyr;
  src:url('./fonts/AvenirNextCyr-Regular.ttf');
  font-weight: 400;
}
@font-face{
  font-family: AvenirNextCyr;
  src:url('./fonts/AvenirNextCyr-Bold.ttf');
  font-weight: 700;
}
@font-face{
  font-family: AvenirNextCyr;
  src:url('./fonts/AvenirNextCyr-Medium.ttf');
  font-weight: 500;
}
@font-face{
  font-family: AvenirNextCyr;
  src:url('./fonts/AvenirNextCyr-Thin.ttf');
  font-weight: 300;
}

#info_block_1{
  height: 134px;
  width: 293px;
  padding: 31px 0;
  top: 60px;
  left: 129px;
}

#info_block_2{
  height: 134px;
  width: 368px;
  padding: 18px 0 20px 0;
  top: -17px;
  right: 28px;
}

#info_block_3{
  height: 134px;
  width: 293px;
  padding: 19px 0;
  top: 189px;
  right: 120px;
}

#info_block_1::before, #info_block_3::before, #info_block_2::before{
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 12px;
  padding: 2px;
  -webkit-mask:
          linear-gradient(#fff 0 0) content-box,
          linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

#info_block_1::before, #info_block_3::before{
  background: linear-gradient(216deg, rgba(53, 127, 238, 0) 33.08%, #357FEE 103.97%);
}

#info_block_2::before{
  background: linear-gradient(65.95deg, rgba(53, 127, 238, 0) -3.41%, #31cf54 95.83%);
}

.ant-form-item-control-input-content input::placeholder {
  color: #f0f0f0;
}

#splat_1{
  position: absolute;
  top: -250px;
  z-index: 1;
  left: 0;
}

#splat_2{
  position: absolute;
  top: -160px;
  right: 0;
  z-index: 1;
}

#splat_3{
  position: absolute;
  top: 700px;
  left: 0;
  z-index: 1;
}

#splat_4{
  position: absolute;
  top: -100px;
  right: 0;
  z-index: 1;
}

#splat_5{
  position: absolute;
  z-index: 1;
  top: -90px;
  right: 50%;
  transform: translateX(50%);
}

#splat_6{
  position: absolute;
  z-index: 1;
  top: -290px;
}

#adv_splat_1{
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
}

#adv_splat_2{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

#adv_splat_3{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

#blog_splat_1{
  position: absolute;
  top: -320px;
}

#blog_splat_2{
  position: absolute;
  top: -480px;
  right: 0;
}

#post_splat_1{
  position: absolute;
  top: -120px;
  left: 0;
}

#post_splat_2{
  position: absolute;
  top: 50px;
  right: 0;
}
#mobile_splat_1, #mobile_splat_2, #mobile_blog_splat_1, #mobile_blog_splat_2, #mobile_post_splat_1, #mobile_post_splat_2, #about_us_mobile_splat_1{
  display: none;
}

#about_us_splat_1{
  position: absolute;
  top: -70%;
}

#about_us_splat_2{
  position: absolute;
  top: -100%;
  right: 0;
}

@media (max-width: 1200px) {
  #info_block_1, #info_block_2, #info_block_3{
    position: relative;
    top: unset;
    left: unset;
    right: unset;
    width: 100%;
  }
  #info_block_1{
    margin-top: 64px;
    padding: 31px 33px;
    margin-bottom: 32px;
  }
  #info_block_2{
    padding: 18px 10px;
    margin-bottom: 32px;
  }
  #info_block_3{
    padding: 19px 33px;
  }
  #adv_splat_1{
    z-index: 1;
  }
  #adv_splat_2{
    z-index: 1;
    top: 0;
    right: 0;
  }
  #adv_splat_3{
    z-index: 1;
    top: 0;
    right: 0;
  }
  #splat_1, #splat_2, #splat_3, #splat_4, #splat_5, #splat_6, #blog_splat_1, #blog_splat_2, #post_splat_1, #post_splat_2, #about_us_splat_1, #about_us_splat_2 {
    display: none;
  }
  #mobile_splat_1{
    position: absolute;
    display: block;
    top: 20px;
    width: 100%;
  }
  #mobile_splat_2{
    position: absolute;
    display: block;
    top: -635px;
  }
  #mobile_blog_splat_1{
    display: block;
    position: absolute;
    top: -52px;
    width: 100%;
  }
  #mobile_blog_splat_2{
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
  }
  #mobile_post_splat_1{
    display: block;
    position: absolute;
    top: -52px;
    width: 100%;
  }
  #mobile_post_splat_2{
    display: block;
    position: absolute;
    top: 25%;
    width: 100%;
  }
  #about_us_mobile_splat_1{
    display: block;
    position: absolute;
    bottom: -25%;
    width: 100%;
  }
}
