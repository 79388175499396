.langingfoqus_wrapper{
    text-align: left;
    background: #171717;
    position: relative;
    overflow: hidden;
}

.container {
    width: 76rem;
    margin: 0 auto;
}

.dots_bg{
    position: absolute;
    z-index: 1;
    width: 100%;
    bottom: 0;
}

/*FIXME Promo styles*/
.promo_wrapper{
    padding-top: 49px;
    position: relative;
}

.promo_logo{
    width: 180px;
    z-index: 1;
    position: relative;
}

.promo_title{
    margin-top: 85px;
    font-size: 80px;
    font-family: Avenir;
    font-weight: 900;
    color: white;
    line-height: 105px;
    z-index: 1;
    position: relative;
}

.promo_title_highlight{
    background: -webkit-linear-gradient(0deg, #31D054, #0F64E4);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.promo_subtitle{
    margin-top: 32px;
    font-size: 22px;
    font-family: Inter;
    font-weight: 400;
    line-height: 26.4px;
    color: white;
}

.promo_contacts{
    display: block;
    text-decoration: underline;
    color: white !important;
    margin-top: 78px;
    font-size: 22px;
    font-weight: 400;
    line-height: 26.4px;
}

.promo_image{
    position: absolute;
    top: 103px;
    right: 0;
    z-index: 1;
}

.promo_blob{
    position: absolute;
    top: 0;
    z-index: 1;
    left: 50%;
    transform: translateX(-50%);
}

/*FIXME KeyMetrics styles*/
.keyMetrics_wrapper{
    margin-top: 340px;
}

.keyMetrics_title{
    font-size: 40px;
    font-weight: 400;
    font-family: Inter;
    line-height: 48px;
    color: white;
    text-align: center;
}

.keyMetrics_subtitle{
    margin-top: 101px;
    font-size: 32px;
    line-height: 24px;
    font-family: Inter;
    font-weight: 300;
    color: white;
}

.keyMetrics_desc{
    margin-top: 40px;
    font-size: 22px;
    line-height: 26.4px;
    font-family: Inter;
    font-weight: 400;
    color: white;
}

.keyMetrics_cards_wrapper{
    margin-top: 56px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.keyMetrics_card {
    background: linear-gradient(32deg, rgba(153,153,153,0.1046393557422969) 0%, rgba(255,255,255,0.2) 100%);
    padding: 41px;
    width: 388px;
    border-radius: 16px;
    height: 571px;
}

.keyMetrics_card_title{
    font-size: 28px;
    font-family: Inter;
    font-weight: 400;
    line-height: 33.6px;
    color: white;
}

.keyMetrics_card_desc{
    margin-top: 29px;
    color: white;
    font-size: 22px;
    line-height: 26.4px;
    font-family: Inter;
    font-weight: 400;
}

/*FIXME Marketing styles*/
.marketing_wrapper{
    margin-top: 152px;
}

.marketing_title{
    font-size: 32px;
    font-weight: 300;
    font-family: Inter;
    line-height: 24px;
    color: white;
}

.marketing_subtitle{
    color: white;
    font-family: Inter;
    font-weight: 400;
    line-height: 26.4px;
    font-size: 22px;
    margin-top: 32px;
}

.marketing_blob{
    position: absolute;
    left: 0;
}

.marketing_cards_wrapper{
    z-index: 2;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 56px;
}

.marketing_card{
    background: linear-gradient(32deg, rgba(153,153,153,0.1046393557422969) 0%, rgba(255,255,255,0.2) 100%);
    padding: 41px 16px 41px 41px;
    border-radius: 16px;
    width: 386px;
    height: 577px;
}

.marketing_card_title{
    color: white;
    font-size: 28px;
    line-height: 32px;
    font-family: Inter;
    font-weight: 400;
}

.marketing_card_desc_wrapper{
    color: white;
    margin-top: 25px;
    font-size: 24px;
    font-weight: 400;
    font-family: Inter;
    line-height: 28.8px;
    margin-bottom: unset;
}

.marketing_graph_1{
    margin-top: 31px;
}

.marketing_graph_2{
    margin-top: 44px;
    width: 100%;
}

.marketing_graph_3{
    margin-top: 44px;
    width: 100%;
}

/*FIXME HowItWorks styles*/
.howItWorks_wrapper{
    margin-top: 191px;
    position: relative;
    z-index: 2;
}

.howItWorks_title{
    font-size: 56px;
    font-weight: 400;
    line-height: 67.2px;
    color: white;
    font-family: Inter;
    text-align: center;
}

.howItWorks_cards_wrapper{
    margin-top: 130px;
    display: flex;
    align-items: center;
}

.howItWorks_card{
    text-align: center;
}

.howItWorks_card_title{
    font-size: 32px;
    color: white;
    font-family: Inter;
    font-weight: 400;
    line-height: 24px;
}

.howItWorks_card_subtitle{
    font-size: 32px;
    line-height: 24px;
    color: white;
    font-family: Inter;
    font-weight: 500;
    text-align: left;
}

.howItWorks_card_desc{
    color: white;
    font-size: 18px;
    line-height: 21.6px;
    font-family: Inter;
    font-weight: 400;
    margin-top: 47px;
    text-align: left;
    margin-bottom: 0px;
    padding: 25px;
}

.howItWorks_progress{
    width: 100%;
    height: 1px;
    background: #c2c2c2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 50px;
}

.howItWorks_progress_point{
    width: 11px;
    height: 11px;
    background: #d9d9d9;
    border-radius: 100%;
}

.point_time{
    font-size: 24px;
    color: white;
    font-family: Inter;
    font-weight: 400;
    line-height: 28.8px;
}

.howItWorks_progress_time{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 37px;
    width: 100%;
}

.howItWorks_1{
    margin-top: 81px;
    margin-bottom: 77px;
}

.howItWorks_2{
    margin-top: 98px;
    margin-bottom: 70px;
}

.howItWorks_3{
    margin-top: 157px;
    margin-bottom: 136px;
}

.progress_vertical{
    display: none;
}

/*FIXME Advantages styles*/
.advantages_wrapper{
    margin-top: 146px;
    z-index: 2;
    position: relative;
}

.advantages_title{
    font-size: 56px;
    font-weight: 400;
    font-family: Inter;
    line-height: 67.2px;
    color: white;
}

.advantages_cards_wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 100px;
}

.advantages_card{
    background: linear-gradient(32deg, rgba(153,153,153,0.1046393557422969) 0%, rgba(255,255,255,0.2) 100%);
    padding: 41px;
    border-radius: 13.56px;
    width: 388px;
    height: 387px;
}

.advantages_card_title{
    font-family: Avenir;
    font-weight: 300;
    font-size: 32px;
    line-height: 24px;
    color: white;
}

.advantages_card_desc{
    margin-top: 56.49px;
    font-family: Inter;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: white;
}

/*FIXME Footer styles*/
.footer_wrapper{
    margin-top: 189px;
    position: relative;
    z-index: 2;
    padding-bottom: 115px;
}

.footer_title{
    font-size: 44px;
    color: white;
    line-height: 52.8px;
    font-family: Inter;
    font-weight: 400;
    text-align: center;
    margin-bottom: 100px;
    margin-top: 89px;
}

.footer_contact{
    font-size: 28px;
    line-height: 47.6px;
    font-family: Inter;
    font-weight: 400;
    color: white;
    text-align: center;
}

.footer_logo_wrapper{
    text-align: center;
    position: relative;
}

.logo_blob{
    position: absolute;
    top: -76%;
    left: 30%;
    z-index: 1;
}

@media (max-width: 1200px) {
    .container{
        width: calc(100% - 32px);
    }
    .dots_bg{
        display: none;
    }
    .promo_image{
        display: none;
    }
    .promo_title{
        font-size: 56px;
        line-height: 120%;
    }
    .promo_subtitle{
        font-size: 20px;
        line-height: 120%;
        font-weight: 400;
    }
    .promo_blob{
        left: 80%;
    }
    .keyMetrics_wrapper{
        margin-top: 129px;
    }
    .keyMetrics_title{
        text-align: left;
        font-size: 32px;
        line-height: 120%;
    }
    .keyMetrics_subtitle{
        margin-top: 57px;
    }
    .keyMetrics_cards_wrapper{
        margin-top: 40px;
        flex-direction: column;
        row-gap: 16px;
    }
    .keyMetrics_card{
        width: 100%;
    }
    .keyMetrics_card_title{
        font-size: 24.75px;
        line-height: 120%;
    }
    .keyMetrics_card_desc{
        font-size: 19.45px;
        line-height: 120%;
    }

    .marketing_wrapper{
        position: relative;
    }
    .marketing_cards_wrapper{
        margin-top: 40px;
        flex-direction: column;
        row-gap: 14.22px;
    }
    .marketing_card{
        width: 100%;
        padding: 36px 14px 36px 36px;
    }
    .marketing_card_title{
        font-size: 24.89px;
        line-height: 28.4px;
    }
    .marketing_card_desc_wrapper{
        font-size: 21.33px;
        line-height: 120%;
    }
    .marketing_card_img{
        width: 100%;
    }
    .marketing_blob{
        transform: translateY(-50%);
        left: -76px;
    }
    .marketing_title, .marketing_subtitle{
        z-index: 2;
        position: relative;
    }

    .howItWorks_wrapper{
        margin-top: 121px;
    }
    .howItWorks_title{
        font-size: 32px;
        line-height: 120%;
        text-align: left;
    }
    .howItWorks_cards_wrapper{
        margin-top: 77px;
        flex-direction: column;
        margin-left: 40px;
    }
    .howItWorks_card_img{
        width: 100%;
    }
    .howItWorks_card_title{
        font-size: 24px;
        line-height: 24px;
    }
    .howItWorks_card_subtitle{
        font-size: 20px;
        line-height: 24px;
    }
    .howItWorks_card_desc{
        font-size: 16px;
        line-height: 120%;
        margin-top: 16px;
        padding-top: unset;
        padding-bottom: unset;
    }
    .howItWorks_1{
        margin-top: 66px;
        margin-bottom: 36px;
        width: 260px;
    }
    .howItWorks_2{
        margin-top: 43px;
        margin-bottom: 61px;
        width: 286px;
    }
    .howItWorks_3{
        margin-top: 76px;
        margin-bottom: 51px;
        width: 325px;
    }
    .howItWorks_progress, .howItWorks_progress_time{
        display: none;
    }
    .progress_vertical{
        display: block;
        position: absolute;
        top: 120px;
    }

    .advantages_wrapper{
        margin-top: 108px;
    }
    .advantages_title{
        font-size: 32px;
        line-height: 120%;
    }
    .advantages_cards_wrapper{
        margin-top: 40px;
        flex-direction: column;
        row-gap: 16px;
    }
    .advantages_card{
        width: 100%;
    }
    .advantages_card_title{
        font-size: 24px;
        line-height: 24px;
    }
    .advantages_card_desc{
        font-size: 18px;
    }

    .footer_wrapper{
        margin-top: 167px;
        padding-bottom: 92px;
    }
    .footer_title{
        font-size: 24px;
        line-height: 120%;
        margin-top: 61px;
        margin-bottom: 40px;
    }
    .footer_contact{
        font-size: 18px;
        line-height: 170%;
    }
    .logo_blob{
        left: -22%;
    }
}
